/**
 * @deprecated logger.error is deprecated and will be replaced by onLogError in a future release.
 * @see https://shopline.atlassian.net/wiki/spaces/EN/pages/2928050271/
 */
const logger = {};

if (typeof Sentry !== 'undefined') {
  logger.log = console.log;
  logger.error = (...errors) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(`
        logger.error is deprecated and will be replaced by onLogError in a future release.
        see https://shopline.atlassian.net/wiki/spaces/EN/pages/2928050271/
      `);
    }

    try {
      if (!errors || errors.length === 0) return;

      if (typeof errors[0] === 'string') {
        const errorMsg = errors[0];

        console.log(errors);
        window.Sentry.withScope((scope) => {
          scope.setLevel(window.Sentry.Severity.Error);
          scope.setExtra('arguments', JSON.stringify(errors.slice(1)));

          window.Sentry.captureMessage(errorMsg);
        });
      } else {
        console.error(errors);
      }
    } catch (e) {
      console.error(e);
    }
  };
} else {
  logger.log = console.log;
  logger.error = console.error;
}

export default logger;
